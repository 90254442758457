<template>
  <v-dialog
    :value="value"
    persistent
    max-width="600px"
    @input="setValue($event)">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        elevation="3"
        color="primary"
        class="btn-printer-settings"
        dark
        v-on="on">
        <v-icon small>
          mdi-cogs
        </v-icon>
        <span class="ml-2">Change Printer</span>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Please Select Printer</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-select
                :value="branchPrinter"
                :items="branch"
                :menu-props="{ offsetY: true }"
                label="Branch"
                item-text="name"
                outlined
                return-object
                hide-details
                @change="getPrinter($event)" />
            </v-col>
            <v-col cols="12">
              <v-select
                :value="printer"
                :items="printerList"
                :loading="getting"
                :menu-props="{ offsetY: true }"
                :disabled="isEnablePrinterList"
                label="IP Printer"
                item-text="ip"
                outlined
                hide-details
                return-object
                @change="setPrinter($event)" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!isEnableSave"
          color="blue darken-1"
          text
          @click="setValue(false)">
          Save
        </v-btn>
      </v-card-actions>
      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import PrinterProvider from '@/resources/PrinterProvider'

const PrinterService = new PrinterProvider()

export default {
  props: {
    branch: {
      type: Array,
      default: () => []
    },
    printerList: {
      type: Array,
      default: () => []
    },
    branchPrinter: {
      type: Object,
      default: null
    },
    printer: {
      type: Object,
      default: null
    },
    value: {
      type: Boolean,
      default: false
    },
    isBranchPrinter: {
      type: Boolean,
      default: false
    },
    isPrinter: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      overlay: false,
      getting: false
    }
  },
  computed: {
    isEnablePrinterList () {
      return this.printerList.length === 0
    },
    isEnableSave () {
      return this.isBranchPrinter && this.printer?.id && this.branchPrinter.branchId === this.printer.branchId
    }
  },
  methods: {
    async getPrinter (value) {
      try {
        this.getting = true
        this.setPrinter(null)
        this.$emit('update:printerList', [])
        this.setBranchPrinter(value)
        const { data } = await PrinterService.getPrinter(value.branchId)
        this.$emit('update:printerList', data)
      } catch (error) {
        console.error('getPrinter', error)
      } finally {
        this.getting = false
      }
    },
    setBranchPrinter (value) {
      this.$emit('update:branchPrinter', value)
    },
    setPrinter (value) {
      this.$emit('update:printer', value)
    },
    setPrinterList (value) {
      this.$emit('update:printerList', value)
    },
    setValue (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped>
button.btn-printer-settings.v-btn {
  position: absolute;
  bottom: 12px;
  right: 12px;
}
</style>
