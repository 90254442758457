import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class PrinterProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_PRINTER_API)
  }

  getPrinter (branchId) {
    this.setHeader(getAuthToken())
    return this.get(`/printers/${branchId}`)
  }

  printReceipt (bill) {
    this.setHeader(getAuthToken())
    return this.post('/prints/receipt', bill)
  }

  printTaxInvoice (data) {
    this.setHeader(getAuthToken())
    return this.post('/prints/tax-invoice', data)
  }
}

export default PrinterProvider
