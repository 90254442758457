<template>
  <v-card class="pa-5">
    <v-row>
      <v-col cols="auto">
        <v-row>
          <v-col
            cols="auto"
            class="py-0">
            <v-select
              v-model="branchSelected"
              :items="branch"
              :menu-props="{ offsetY: true }"
              item-value="value"
              item-text="name"
              label="Branch"
              class="select-warehouse"
              auto-select-first
              outlined
              hide-details
              dense
              @change="optionsUpdated()" />
          </v-col>
          <v-col
            cols="auto"
            class="py-0">
            <v-select
              v-model="channelSelected"
              :items="channel"
              :menu-props="{ offsetY: true }"
              item-value="value"
              item-text="label"
              label="Channel"
              class="select-warehouse"
              auto-select-first
              outlined
              hide-details
              dense
              @change="optionsUpdated()" />
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
      <v-col cols="auto">
        <v-text-field
          v-model="searchText"
          class="search-input"
          :height="28"
          hide-details
          dense
          solo
          @input="searchOrder(true, $event)">
          <template v-slot:append>
            <v-icon small>
              mdi-magnify
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="auto"
        class="mx-auto">
        <v-data-table
          :headers="header"
          :items="orderData"
          :options.sync="options"
          :items-per-page="options.itemsPerPage"
          :loading="loading"
          :server-items-length="itemsLength"
          :height="530"
          class="elevation-1 mb-5"
          hide-default-footer
          @update:page="optionsUpdated()"
          @update:sort-by="optionsUpdated()"
          @update:sort-desc="optionsUpdated()">
          <template #[`header.actions`]>
            <v-btn
              :loading="loading"
              icon
              small
              @click="optionsUpdated()">
              <v-icon>
                mdi-autorenew
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                {{ item.order.id }}
              </td>
              <td>
                {{ mapDateTime(item.order.createTime, 'YYYY-MM-DD HH:mm') }}
              </td>
              <td class="text-center">
                {{ mapChannel(item.channel) }}
              </td>
              <td>
                {{ getBranchName(item) }}
              </td>
              <td class="text-end">
                {{ item.order.net | showFullPriceFormat() }}
              </td>
              <td>
                <div class="d-flex justify-end">
                  <v-btn
                    x-small
                    class="mr-2"
                    :disabled="!(item.member && item.member.id)"
                    @click="selectOrder(item)">
                    <v-icon
                      class="mr-2"
                      small>
                      mdi-printer
                    </v-icon>
                    <span>Print</span>
                  </v-btn>
                  <v-icon
                    small
                    color="success"
                    class="tax-status"
                    :class="{
                      'tax-status-printed': checkPrintStatus(item.taxInvoiceStatus)
                    }">
                    mdi-checkbox-marked-circle
                  </v-icon>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-pagination
          v-if="paginationEnable"
          v-model="options.page"
          :length="pageCount"
          :disabled="loading"
          :total-visible="8" />
      </v-col>
    </v-row>
    <modal-select-printer
      v-model="modalPrinter"
      :branch="posBranch"
      :printer-list.sync="printerList"
      :branch-printer.sync="branchPrinter"
      :printer.sync="printer"
      :is-branch-printer="isBranchPrinter"
      :is-printer="isPrinter" />
    <modal-tax-invoice
      v-model="modalSelectAddress"
      :order-selected="orderSelected"
      :member-data.sync="memberData"
      :printer="printer" />
  </v-card>
</template>

<script>

import MemberProvider from '@/resources/MemberProvider'
import { mapActions } from 'vuex'
import WarehouseProvider from '@/resources/WarehouseProvider'
import ModalSelectPrinter from '../components/ModalSelectPrinter.vue'
import ModalTaxInvoice from '../components/ModalTaxInvoice.vue'

const WarehouseService = new WarehouseProvider()
const MemberService = new MemberProvider()

export default {
  components: {
    modalSelectPrinter: ModalSelectPrinter,
    modalTaxInvoice: ModalTaxInvoice
  },
  data () {
    return {
      modalSelectAddress: false,
      branchSelected: 0,
      posBranch: [],
      searchText: '',
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['order.createTime'],
        sortDesc: [false]
      },
      header: [
        {
          text: 'ID',
          value: 'order.id',
          sortable: false,
          width: 200
        },
        {
          text: 'Create Time',
          value: 'order.createTime',
          sortable: false,
          width: 175
        },
        {
          text: 'Channel',
          value: 'channel',
          sortable: false,
          align: 'center',
          width: 100
        },
        {
          text: 'Branch',
          value: 'branch.name',
          sortable: false,
          width: 200
        },
        {
          text: 'Net',
          value: 'order.net',
          align: 'center',
          sortable: false,
          width: 100
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'end'
        }
      ],
      orderData: [],
      getting: false,
      modalLoading: false,
      isEdited: false,
      dialog: false,
      loading: false,
      itemsLength: 0,
      pageCount: 0,
      channel: [
        {
          label: 'All',
          value: 'all'
        },
        {
          label: 'POS',
          value: 'pos'
        },
        {
          label: 'Website',
          value: 'web'
        }
      ],
      channelSelected: 'all',
      searchTimeout: null,
      branchPrinter: null,
      printer: null,
      printerList: [],
      modalPrinter: false,
      orderSelected: null,
      memberData: null,
      memberGetting: false
    }
  },
  computed: {
    branch () {
      const branch = this.posBranch.map((r) => ({
        name: r.name,
        value: r.branchId
      }))
      return [
        {
          name: 'All',
          value: 0
        },
        ...branch
      ]
    },
    isBranchPrinter () {
      return !!(this.branchPrinter && this.branchPrinter.branchId)
    },
    isPrinter () {
      return !!(this.printer && this.printer.ip && this.printer.branchId)
    },
    paginationEnable () {
      return this.pageCount > 1
    }
  },
  async created () {
    this.initPrinter()
    this.getAttribute(this.options)
    await this.getWarehouse()
  },
  methods: {
    ...mapActions({
      setErrorPage: 'Components/setErrorPage'
    }),
    async getWarehouse () {
      try {
        const { data } = await WarehouseService.getWarehouse()
        this.posBranch = data.map((d) => ({
          name: d.name,
          branchId: d.id,
          posId: `POS NO. ${d.id}`,
          unitId: d.id,
          code: d.code
        }))
      } catch (error) {
        console.error('getWarehouse: ', error)
        this.setErrorPage(error.code)
      }
    },
    initPrinter () {
      if (!(this.isBranchPrinter && this.isPrinter)) {
        this.modalPrinter = true
      }
    },
    mapDateTime (dateTime, currentFormat) {
      return this.$dayjs(dateTime, currentFormat).add(7, 'hour').format('DD-MM-YYYY HH:mm')
    },
    async getAttribute (options) {
      if (!this.getting) {
        try {
          this.getting = true
          this.loading = true
          const { data } = await MemberService.memberOrderlist({
            ...options,
            search: this.searchText,
            currentState: 'paid',
            channel: this.channelSelected !== 'all' ? this.channelSelected : null,
            branchId: this.branchSelected ? this.branchSelected : null
          })
          this.itemsLength = data.total
          this.pageCount = data.pages
          this.orderData = data.results
        } catch (error) {
          console.error('getAttribute: ', error)
          this.setErrorPage(error.code)
        } finally {
          this.loading = false
          this.getting = false
        }
      }
    },
    async getMemberById (id) {
      let getFinally = null
      if (!this.memberGetting) {
        try {
          this.memberGetting = true
          const { data } = await MemberService.getMemberById(id)
          getFinally = data
        } catch (error) {
          console.error('getMemberById', error)
          this.setErrorPage(error.code)
        } finally {
          this.memberGetting = false
        }
      }
      return getFinally
    },
    async selectOrder (data) {
      const member = await this.getMemberById(data.member.id)
      if (member.id) {
        this.orderSelected = data
        this.memberData = JSON.parse(JSON.stringify(member))
        this.modalSelectAddress = true
      }
    },
    optionsUpdated () {
      this.getAttribute(this.options)
    },
    searchOrder (isWait = false) {
      this.isLoadComplete = false
      this.page = 0
      this.getting = false
      if (isWait) {
        clearTimeout(this.searchTimeout)
        this.searchTimeout = null
        this.searchTimeout = setTimeout(() => {
          this.getAttribute(this.options)
        }, 1000)
      } else {
        this.getAttribute(this.options)
      }
    },
    checkPrintStatus (status) {
      return status === 'printed'
    },
    mapChannel (channel) {
      const data = this.channel.find((r) => r.value === channel) || {}
      return data?.label || '-'
    },
    getBranchName (item) {
      return item?.branch?.name || '-'
    }
  }
}
</script>

<style lang="scss" scoped>
.tax-status {
  opacity: 0;
}
.tax-status.tax-status-printed {
  opacity: 1;
}
</style>
