<template>
  <v-dialog
    :value="value"
    persistent
    max-width="1200"
    @input="setValue($event)">
    <div class="d-flex">
      <v-card class="card-left">
        <v-card-title>
          <span class="headline">ข้อมูลลูกค้า</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-fade-transition mode="out-in">
              <v-row v-if="!editing">
                <v-col cols="12">
                  <div class="select-data-box selected-member">
                    <div class="title-card">
                      <p class="title">
                        ที่อยู่ลูกค้า
                      </p>
                    </div>
                    <div
                      v-if="selectedIndex > -1"
                      class="body-card">
                      <v-btn
                        class="btn-edit"
                        small
                        @click="setEditing(true)">
                        แก้ไข
                      </v-btn>
                      <p class="body-1">
                        <b>ชื่อ-สกุล</b>
                        <span>{{ mapFullName(selectedAddress) }}</span>
                      </p>
                      <p class="body-1">
                        <b>เลขประจำตัวผู้เสียภาษี</b>
                        <span>{{ selectedAddress.taxId || '-' }}</span>
                      </p>
                      <p class="body-1">
                        <b>ที่อยู่</b>
                        <span>{{ mapAddress(selectedAddress) }}</span>
                      </p>
                      <p class="body-1">
                        <b>เบอร์โทรศัพท์</b>
                        <span>{{ selectedAddress.contactNo || '-' }}</span>
                      </p>
                    </div>
                    <div
                      v-else
                      class="body-card empty-data">
                      <span class="caption">
                        กรุณาเลือกที่อยู่ของลูกค้า
                      </span>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="select-data-box">
                    <div class="title-card">
                      <p class="title">
                        ที่อยู่ร้านค้า
                      </p>
                    </div>
                    <div class="body-card">
                      <p class="body-1">
                        <b>สาขา</b>
                        <span>{{ selectedBranch.name }}</span>
                      </p>
                      <p class="body-1">
                        <b>เลขประจำตัวผู้เสียภาษี</b>
                        <span>{{ selectedBranch.taxId }}</span>
                      </p>
                      <p class="body-1">
                        <b>ที่อยู่</b>
                        <span>
                          {{
                            `${selectedBranch.address}\n${selectedBranch.address2}\n`
                              + `ตำบล/แขวง ${selectedBranch.subDistrict} อำเภอ/เขต ${selectedBranch.district}`
                              + `จังหวัด ${selectedBranch.stateProvince} ${selectedBranch.postcode}`
                          }}
                        </span>
                      </p>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <validation-observer
                v-else
                ref="taxMemberDetail"
                tag="form"
                @submit.prevent="save()">
                <v-row>
                  <v-col cols="12">
                    <v-radio-group
                      v-model="formMember.note"
                      row>
                      <v-radio
                        label="บุคคลธรรมดา"
                        value="personal" />
                      <v-radio
                        label="นิติบุคคล"
                        value="corporation" />
                    </v-radio-group>
                  </v-col>
                  <v-col
                    v-if="!isCorporation"
                    cols="2">
                    <gw-text-field
                      v-model="formMember.title"
                      :rules="textFieldRules"
                      label="คำนำหน้าชื่อ *"
                      dense />
                  </v-col>
                  <v-col :cols="colsName">
                    <gw-text-field
                      v-model="formMember.firstName"
                      :rules="textFieldRules"
                      :label="labelName"
                      dense />
                  </v-col>
                  <v-col
                    v-if="!isCorporation"
                    cols="5">
                    <gw-text-field
                      v-model="formMember.lastName"
                      :rules="textFieldRules"
                      label="นามสกุล *"
                      dense />
                  </v-col>
                  <v-col cols="12">
                    <gw-text-field
                      v-model="formMember.taxId"
                      v-mask="'#-####-#####-##-#'"
                      :rules="textFieldRules"
                      label="เลขประจำตัวผู้เสียภาษี *"
                      return-masked-value
                      dense />
                  </v-col>
                  <v-col cols="12">
                    <gw-text-field
                      v-model="formMember.address"
                      :rules="textFieldRules"
                      label="ที่อยู่ 1 *"
                      dense />
                  </v-col>
                  <v-col cols="12">
                    <gw-text-field
                      v-model="formMember.address2"
                      label="ที่อยู่ 2"
                      dense />
                  </v-col>
                  <v-col cols="6">
                    <gw-text-field
                      v-model="formMember.district"
                      :rules="textFieldRules"
                      label="แขวง/ตำบล *"
                      dense />
                  </v-col>
                  <v-col cols="6">
                    <gw-text-field
                      v-model="formMember.states"
                      :rules="textFieldRules"
                      label="เขต/อำเภอ *"
                      dense />
                  </v-col>
                  <v-col cols="6">
                    <gw-text-field
                      v-model="formMember.province"
                      :rules="textFieldRules"
                      label="จังหวัด *"
                      dense />
                  </v-col>
                  <v-col cols="6">
                    <gw-text-field
                      v-model="formMember.postcode"
                      v-mask="'#####'"
                      :rules="textFieldRules"
                      label="รหัสไปรษณีย์ *"
                      dense />
                  </v-col>
                  <v-col cols="12">
                    <gw-text-field
                      v-model="formMember.contactNo"
                      :rules="textFieldRules"
                      label="เบอร์โทรศัพท์ *"
                      dense />
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col cols="auto">
                    <v-btn
                      class="mr-2"
                      color="primary"
                      @click="setEditing(false)">
                      back
                    </v-btn>
                    <v-btn
                      color="primary"
                      type="submit">
                      Save
                    </v-btn>
                  </v-col>
                </v-row>
              </validation-observer>
            </v-fade-transition>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close()">
            ปิด
          </v-btn>
          <v-btn
            color="blue darken-1"
            type="submit"
            :disabled="!isEnabledSave"
            text
            @click="print()">
            พิมพ์ใบกำกับ
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card class="card-right">
        <v-card-title>
          <span class="headline">ที่อยู่อื่น ๆ</span>
          <v-spacer />
          <v-btn @click="setEditing(true, true)">
            เพิ่ม
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container class="pa-0">
            <v-row
              v-if="memberData"
              class="address-list">
              <v-col
                v-for="(member, index) in memberData.addresses"
                :key="index"
                :cols="12"
                class="row-address-box">
                <gw-icon-hover
                  class="remove-address-box"
                  icon-name="mdi-delete"
                  icon-hover="mdi-delete-empty"
                  @click="removeAddress(index)" />
                <div
                  class="address-box"
                  :class="{ 'active': checkCurrentSelected(index) }"
                  @click="selectMember(index)">
                  <p class="subtitle-2">
                    <b>ชื่อ-สกุล</b>
                  </p>
                  <p class="body-1">
                    {{ mapFullName(member) }}
                  </p>
                  <p class="subtitle-2">
                    <b>เลขประจำตัวผู้เสียภาษี</b>
                  </p>
                  <p class="body-1">
                    {{ member.taxId || '-' }}
                  </p>
                  <p class="subtitle-2">
                    <b>เบอร์โทรศัพท์</b>
                  </p>
                  <p class="body-1">
                    {{ member.contactNo || '-' }}
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <v-overlay
        :value="loading"
        absolute>
        <v-progress-circular
          :width="3"
          indeterminate />
      </v-overlay>
    </div>
  </v-dialog>
</template>

<script>
import MemberProvider from '@/resources/MemberProvider'
import PrinterProvider from '@/resources/PrinterProvider'
import WarehouseProvider from '@/resources/WarehouseProvider'
import { mapActions, mapGetters } from 'vuex'

const MemberService = new MemberProvider()
const PrinterService = new PrinterProvider()
const WarehouseService = new WarehouseProvider()

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    orderSelected: {
      type: Object,
      default: null
    },
    memberData: {
      type: Object,
      default: null
    },
    printer: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      textFieldRules: {
        required: true
      },
      formMember: {
        note: 'personal',
        title: '',
        firstName: '',
        lastName: '',
        taxId: '',
        address: '',
        address2: '',
        district: '',
        states: '',
        province: '',
        postcode: '',
        contactNo: '',
        country: 'ไทย'
      },
      selectedIndex: -1,
      selectedAddress: {},
      selectedBranch: {},
      selectedPrinter: {},
      storeAddress: [],
      editing: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      store: 'Store/store'
    }),
    isEnabledSave () {
      return !this.editing && this.selectedAddressNotEmpty()
    },
    isCorporation () {
      return this.editing && this.formMember.note === 'corporation'
    },
    labelName () {
      return this.isCorporation ? 'ชื่อบริษัท *' : 'ชื่อ *'
    },
    colsName () {
      return this.isCorporation ? 12 : 5
    }
  },
  watch: {
    async value (newValue) {
      if (newValue) {
        await this.getWarehouse()
        this.getSelectedBranch()
      }
    }
  },
  methods: {
    ...mapActions({
      setErrorPage: 'Components/setErrorPage',
      setSnackbar: 'Components/setSnackbar'
    }),
    async getWarehouse () {
      try {
        const { data } = await WarehouseService.getWarehouse()
        this.storeAddress = data
        this.posBranch = data.map((d) => ({
          name: d.name,
          branchId: d.id,
          posId: `POS NO. ${d.id}`,
          unitId: d.id,
          code: d.code
        }))
      } catch (error) {
        console.error('getWarehouse: ', error)
        this.setErrorPage(error.code)
      }
    },
    selectedAddressNotEmpty () {
      let isNotEmpty = true
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.selectedAddress) {
        if (Object.prototype.hasOwnProperty.call(this.selectedAddress, key)) {
          if (this.selectedAddress.note === 'corporation') {
            if (key !== 'title' && key !== 'lastName' && key !== 'country' && key !== 'address2' && this.selectedAddress[key] === '') {
              isNotEmpty = false
              break
            }
          } else if (key !== 'country' && key !== 'address2' && this.selectedAddress[key] === '') {
            isNotEmpty = false
            break
          }
        }
      }
      if (!this.selectedAddress.taxId) {
        isNotEmpty = false
      }
      return isNotEmpty
    },
    async getSelectedBranch () {
      const defaultAddress = {
          branchId: 964,
          name: 'website',
          channel: 'web',
          taxId: '0-1055-61034-56-1',
          address: 'บริษัท เจนเทิลวูแมน จำกัด (สำนักงานใหญ่)\n999/9 อาคารดิออฟฟิศเศส แอท เซ็นทรัลเวิลด์',
          address2: 'ห้องเลขที่ OFMH 3806-3812 ชั้นที่ 38 ถนนพระราม 1',
          subDistrict: 'ปทุมวัน',
          district: 'ปทุมวัน',
          stateProvince: 'กรุงเทพมหานคร',
          postcode: '10330',
          country: 'ประเทศไทย'
        }
      if (this.orderSelected.channel === 'pos') {
        const selectedBranch = this.storeAddress.find((warehouse) => warehouse.id === this.orderSelected.branch.id)
        this.selectedBranch = selectedBranch || defaultAddress
      } else {
        this.selectedBranch = defaultAddress
      }

      this.selectedPrinter = this.posBranch.find((r) => r.branchId === this.printer.branchId)
    },
    mapFullName (member) {
      return (member.title || member.firstName || member.lastName) ? `${member.title} ${member.firstName} ${member.lastName}` : '-'
    },
    mapAddress (member) {
      return (member.address || member.address2 || member.district || member.states || member.province || member.postcode)
      ? `${member.address} ${member.address2} ${member.district} ${member.states} ${member.province} ${member.postcode}` : '-'
    },
    checkCurrentSelected (index) {
      return this.selectedIndex === index
    },
    removeAddress (index) {
      this.memberData.addresses.splice(index, 1)
      this.updateMember(this.memberData)
      if (this.selectedIndex === index) {
        this.selectedIndex = -1
        this.selectedAddress = {}
      }
    },
    selectMember (index) {
      if (this.selectedIndex === index) {
        this.selectedIndex = -1
        this.selectedAddress = {}
      } else {
        this.selectedIndex = index
        this.selectedAddress = this.memberData.addresses[index]
      }
    },
    async updateMember (member) {
      try {
        this.loading = true
        const { data } = await MemberService.updateMember(member.id, member)
        this.$emit('update:memberData', data)
        this.setSnackbar({
          value: true,
          message: this.$t('อัปเดทข้อมูลสำเร็จ'),
          type: 'success'
        })
      } catch (error) {
        console.error(error)
      } finally {
        this.editing = false
        this.loading = false
      }
    },
    async save () {
      const vaid = await this.$refs.taxMemberDetail.validate()
      if (vaid) {
        const newData = JSON.parse(JSON.stringify(this.formMember))
        if (this.selectedIndex > -1) {
          this.memberData.addresses.splice(this.selectedIndex, 1, newData)
        } else {
          this.memberData.addresses.push(newData)
          this.selectedIndex = this.memberData.addresses.length - 1
        }
        this.selectedAddress = newData
        this.updateMember(this.memberData)
      }
    },
    close () {
      this.setValue(false)
    },
    setEditing (value, blank = false) {
      if (blank) {
        this.selectedIndex = -1
        this.selectedAddress = {}
      }
      if (value) {
        Object.keys(this.formMember).forEach((key) => {
          if (blank && key === 'note') {
            this.formMember[key] = 'personal'
          } else if (blank) {
            this.formMember[key] = ''
          } else if (key === 'note') {
            this.formMember[key] = this.checkAddress(key) || 'personal'
          } else {
            this.formMember[key] = this.checkAddress(key) || ''
          }
        })
      }
      this.editing = value
    },
    checkAddress (key) {
      if (this.selectedIndex > -1) {
        return this.memberData.addresses[this.selectedIndex][key]
      }
      return ''
    },
    setValue (value) {
      this.$emit('input', value)
      if (!value) {
        this.editing = false
        this.selectedIndex = -1
        this.selectedAddress = {}
      }
    },
    mapBranchAddress (branch) {
      return `${branch.address}\n${branch.address2}\n${branch.subDistrict} ${branch.district} ${branch.stateProvince} ${branch.postcode}`
    },
    groupOrderItem (items, channel) {
      if (channel === 'pos') {
        const skus = JSON.parse(JSON.stringify(items))
        const groupSkus = []
        // eslint-disable-next-line no-restricted-syntax
        for (const sku of skus) {
          const index = groupSkus.findIndex(
            (item) => item.skuId === sku.skuId && parseFloat(item.flagPrice) === parseFloat(sku.price)
          )
          if (index === -1) {
            sku.flagPrice = parseFloat(sku.price)
            groupSkus.push(sku)
          } else {
            groupSkus[index].categories = []
            groupSkus[index].tags = []
            groupSkus[index].amount = parseInt(groupSkus[index].amount)
              + parseInt(sku.amount)
            groupSkus[index].price = parseFloat(groupSkus[index].price)
              + (parseInt(sku.amount) * parseFloat(sku.price))
            groupSkus[index].fullPrice = parseFloat(groupSkus[index].fullPrice)
              + (parseInt(sku.amount) * parseFloat(sku.fullPrice))
          }
        }
        return groupSkus
      }
      return items
    },
    async print () {
      try {
        this.loading = true
        const items = this.groupOrderItem(this.orderSelected.orderItems, this.orderSelected.channel)
        const addressPrint = `${this.selectedBranch.address}\n${this.selectedBranch.address2}\n`
        + `ตำบล/แขวง ${this.selectedBranch.subDistrict} อำเภอ/เขต ${this.selectedBranch.district} `
        + `จังหวัด ${this.selectedBranch.stateProvince} ${this.selectedBranch.postcode}`
        const printData = {
          billId: this.orderSelected.order.id,
          member: {
            id: this.memberData.id,
            tel: this.memberData.tel,
            ...this.selectedAddress,
            country: 'ไทย'
          },
          branchPos: {
            branchId: this.selectedPrinter.branchId,
            address: addressPrint,
            address2: '',
            district: this.selectedBranch.subDistrict,
            states: this.selectedBranch.district,
            province: this.selectedBranch.stateProvince,
            country: this.selectedBranch.country,
            postcode: this.selectedBranch.postcode,
            tel: '',
            name: this.selectedBranch.name,
            posId: this.selectedPrinter.posId,
            taxId: this.selectedBranch.taxId,
            unitId: this.selectedPrinter.unitId,
            code: this.selectedPrinter.code
          },
          payment: {
            type: 'credit',
            amount: this.orderSelected.order.net,
            name: 'credit',
            change: 0
          },
          items: items.map((r) => ({
            ...r,
            id: `${r.skuId}`,
            productId: `${r.productId}`,
            option1: r.size,
            option2: r.color,
            variant1: 'Size',
            variant2: 'Color',
            tags: [],
            categories: []
          })),
          discount: this.orderSelected.order.discount,
          print: {
            status: 'pending',
            ip: this.printer.ip
          },
          fullPrice: this.orderSelected.order.net + this.orderSelected.order.discount,
          price: this.orderSelected.order.net,
          cashier: {
            email: this.orderSelected?.cashier?.email ? this.orderSelected.cashier.email : '',
            id: this.orderSelected?.cashier?.id ? parseInt(this.orderSelected.cashier.id) : 0,
            firstName: this.orderSelected?.cashier?.name ? this.orderSelected.cashier.name.split(' ')[0] : '',
            lastName: this.orderSelected?.cashier?.name ? this.orderSelected.cashier.name.split(' ')[1] : ''
          },
          currency: this.orderSelected.channel === 'online_international' ? 'ดอลลาร์สหรัฐ' : 'บาท',
          storeDisplayName: this.store?.name || 'GW',
          storeLogo: 'https://gentlewoman-management.s3-ap-southeast-1.amazonaws.com/receipt-image/gw-logo-shop.png',
          displayTime: this.$dayjs(this.orderSelected.createdAt).format('DD-MM-YYYY HH:mm')
        }
        const { data } = await PrinterService.printTaxInvoice(printData)
        this.setSnackbar({
          value: true,
          message: this.$t(`พิมพ์บิล ${data.billId} สำเร็จ`),
          type: 'success'
        })
        this.setValue(false)
      } catch (error) {
        console.error('print', error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card-right {
  width: 25%;
  min-width: 300px;
  margin-left: 5px;
}
.card-left {
  width: 75%;
}
.address-box {
  border: 1px dotted;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  transition: all .2s;
  user-select: none;
}
.address-box.active {
  background-color: #a2a2a2;
  color: #fff;
}
.address-box p {
  margin: 0;
}
p.body-1>b {
  margin-right: 10px;
}
.btn-edit {
  position: absolute;
  top: 35px;
  right: 20px;
  transform: translateY(-50%);
}
.select-data-box {
  position: relative;
  padding: 30px 25px 9px 25px;
  border: 1px solid;
  border-radius: 10px;
  .title-card {
    position: absolute;
    width: fit-content;
    padding: 5px;
    background-color: #ffffff;
    top: -20px;
    p {
      margin: 0;
    }
  }
}
.body-card.empty-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
  width: 100%;
}
.address-list {
  overflow-y: auto;
  max-height: 500px;
}
.row-address-box {
  position: relative;
  .remove-address-box {
    position: absolute;
    right: 15px;
    top: 15px;
  }
}
</style>
